.job-card {
  background: #fff;
  padding: 20px 25px;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  position: relative;
}

.job-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 2px;
}

.job-employer {
  font-size: 1.1rem;
  margin-bottom: 10px;
  opacity: 0.7;
}

.job-data {
  font-size: 1rem;
  opacity: 0.5;
}

.job-desktop-wage {
  position: absolute;
  bottom: 0;
  right: 15px;
  opacity: 0.7;
  font-size: 1rem;
}

.mobile-job-location {
  display: none;
}

.job-mobile-wage, .job-mini-mobile-wage {
  display: none;
}

@media (max-width: 992px) {
  .job-employer {
    margin-bottom: 5px;
  }
  
  .job-location {
    display: block;
  }

  .location-break {
    display: none;
  }

  .apply-btn {
    font-size: 0;
  }

  .apply-btn:after {
    content: 'Apply';
    font-size: 1rem;
  }

  .job-desktop-wage {
    font-size: 0.9rem;
  }
}


@media (max-width: 768px) {
  .mobile-job-location {
    display: unset;
  }

  .job-location {
    display: none;
  }

  .job-desktop-wage {
    top: -75px;
  }

  .apply-btn {
    margin-top: 20px;
  }

  .float-back-left {
    float: left !important;
    width: 100%;

  }

  .apply-btn {
    font-size: 1rem;
    width: 100%;
  }

  .apply-btn:after {
    content: '';
  }

  .job-desktop-wage {
    display: none;
  }

  .job-mobile-wage {
    display: unset;
    font-size: 1rem;
    position: absolute;
    top: 3px;
    right: 15px;;
  }
  
}

@media (max-width: 768px) {
  .job-location {
    display: block;
  }

  .mobile-job-location {
    display: none;
  }

  .job-title {
    display: block;
    /* margin-top: 7px; */
  }

  .title-break {
    display: none;
  }
}


@media (max-width: 568px) {
  .job-mini-mobile-wage {
    display: block;
    margin-top: 10px;
    opacity: 1 !important;
    color: #000;
    font-weight: bold;
  }

  .job-mobile-wage {
    display: none;
  }
}

